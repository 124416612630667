import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { catchError, map, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UpdatePasswordDto } from '../../modules/backoffice/user-profile/user-profile.types';
import { ApiResult } from '../../shared/types/api-result.types';
import { BaseService } from '../services/base.service';
import { skipHeader } from './auth.interceptor';
import {
    ForgotPasswordDto,
    LoginDto,
    LoginResponse,
    ResetPasswordDto,
    SsPermission,
} from './auth.types';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
    private _authenticated: boolean | 'last_check_error' = false;
    private _accessToken: string = null;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        this._accessToken = token;
    }

    get accessToken(): string {
        return this._accessToken;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param dto
     */
    forgotPassword(dto: ForgotPasswordDto): Observable<ApiResult<void>> {
        return this._httpClient
            .post<ApiResult<void>>(
                environment.apiUrl + 'backoffice/auth/forgot-password',
                dto,
                {
                    withCredentials: true,
                    headers: { [skipHeader]: 'true' },
                }
            )
            .pipe(catchError(this.handleError));
    }

    /**
     * Reset password
     *
     * @param dto
     */
    resetPassword(dto: ResetPasswordDto): Observable<ApiResult<void>> {
        return this._httpClient
            .post<ApiResult<void>>(
                environment.apiUrl + 'backoffice/auth/reset-password',
                dto,
                {
                    withCredentials: true,
                    headers: { [skipHeader]: 'true' },
                }
            )
            .pipe(catchError(this.handleError));
    }

    /**
     * Sign in
     *
     * @param dto
     */
    signIn(dto: LoginDto): Observable<ApiResult<LoginResponse>> {
        // Throw error, if the user is already logged in
        //Note: keep true comparison, otherwise the check would also pass the any error string
        if (this._authenticated === true) {
            return throwError(() => 'User is already logged in.');
        }

        return this._httpClient
            .post<ApiResult<LoginResponse>>(
                environment.apiUrl + 'backoffice/auth/login',
                dto,
                {
                    withCredentials: true,
                    headers: { [skipHeader]: 'true' },
                }
            )
            .pipe(
                catchError(this.handleError),
                switchMap((response) => this.handleLoginResponse(response))
            );
    }

    private handleLoginResponse(
        response: ApiResult<LoginResponse>
    ): Observable<ApiResult<LoginResponse>> {
        if (!response.isSuccess) {
            return of(response);
        }

        // Process the authentication
        return this.processAuthentication(response.result).pipe(
            map(() => response)
        );
    }

    private processAuthentication(result: LoginResponse) {
        this.storeAuthenticationDetails(result);

        if (this._userService.userPermissions.length == 0) {
            return this.getUserPermissions();
        }
        return of(null);
    }

    getUserPermissions(): Observable<ApiResult<SsPermission[]>> {
        return this._httpClient
            .get<
                ApiResult<SsPermission[]>
            >(`${environment.apiUrl}backoffice/auth/permissions`)
            .pipe(
                catchError(this.handleError),
                switchMap((data) => {
                    this._userService.userPermissions = data.result;
                    return of(data);
                })
            );
    }

    private storeAuthenticationDetails(result: LoginResponse): void {
        this.accessToken = result?.jwt;

        this._authenticated = true;

        this._userService.user = {
            email: result.email,
            id: result.id,
            username: result.username,
            fullName: result.fullName,
            avatar: '',
        };
    }

    /**
     * Force sign out
     */
    forceSignOut(): void {
        this.signOutCleanup();
        window.location.reload();
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        return this._httpClient
            .post(
                `${environment.apiUrl}backoffice/auth/logout`,
                {},
                {
                    headers: { [skipHeader]: 'true' },
                    withCredentials: true, //Include cookies
                }
            )
            .pipe(
                catchError(() => {
                    this.signOutCleanup();
                    return of(true);
                }),
                switchMap(() => {
                    this.signOutCleanup();
                    return of(true);
                })
            );
    }

    private signOutCleanup() {
        this._accessToken = null;
        this._userService.update(null);
        this._userService.userPermissions = [];
        this._authenticated = false;
    }

    refreshToken(): Observable<ApiResult<LoginResponse>> {
        return this._httpClient
            .get<ApiResult<LoginResponse>>(
                `${environment.apiUrl}backoffice/auth/refresh`,
                {
                    headers: { [skipHeader]: 'true' },
                    withCredentials: true,
                }
            )
            .pipe(
                catchError(this.handleError),
                switchMap((response: ApiResult<LoginResponse>) =>
                    this.handleLoginResponse(response)
                )
            );
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        //If authenticated is "last_check_error", it means the latest check failed and we did not re-login since
        if (this._authenticated === 'last_check_error') return of(false);

        // Verify token
        if (this._accessToken && !AuthUtils.isTokenExpired(this._accessToken)) {
            this._authenticated = true;
            return of(true);
        }

        // Try to get a new token by API
        return this.refreshToken().pipe(
            switchMap(() => {
                return of(true);
            }),
            catchError(() => {
                //Error means no refresh token or it has expired
                //Set special value to indicate latest check has failed
                this._authenticated = 'last_check_error';

                return of(false);
            })
        );
    }

    updatePassword(
        updatePasswordDto: UpdatePasswordDto
    ): Observable<ApiResult<void>> {
        return this._httpClient
            .put<
                ApiResult<void>
            >(`${environment.apiUrl}backoffice/auth/update-password`, updatePasswordDto)
            .pipe(catchError(this.handleError));
    }
}
