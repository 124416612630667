import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import {
    Observable,
    catchError,
    from,
    switchMap,
    take,
    throwError,
} from 'rxjs';
import { ApiResult } from '../../shared/types/api-result.types';
import { LoginResponse } from './auth.types';

export const skipHeader = 'auth-interceptor-skip';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const router = inject(Router);

    //Verify if the call should skip the interceptor by using our custom, angular related, skip header.
    const skipIntercept = req.headers.has(skipHeader);

    if (skipIntercept) {
        req = req.clone({
            headers: req.headers.delete(skipHeader),
        });
        return next(req);
    }

    const authService = inject(AuthService);

    // Clone the request object
    let newReq = req.clone();

    // Request
    if (
        authService.accessToken &&
        !AuthUtils.isTokenExpired(authService.accessToken)
    ) {
        newReq = req.clone({
            headers: req.headers.set(
                'Authorization',
                'Bearer ' + authService.accessToken
            ),
        });
    }

    function refreshTokenMethod(
        newReq: HttpRequest<unknown>,
        next: HttpHandlerFn
    ): Observable<HttpEvent<unknown>> {
        return from(authService.refreshToken()).pipe(
            take(1),
            switchMap((response: ApiResult<LoginResponse>) => {
                if (response.isSuccess) {
                    newReq = newReq.clone({
                        headers: req.headers.set(
                            'Authorization',
                            'Bearer ' + authService.accessToken
                        ),
                    });
                    return next(newReq);
                } else {
                    authService.forceSignOut();
                    return throwError(() => new Error('Invalid refresh token'));
                }
            }),
            catchError((error) => {
                authService.forceSignOut();

                return throwError(() => error);
            })
        );
    }

    // Response
    return next(newReq).pipe(
        catchError((error) => {
            // Catch "401 Unauthorized" responses: these are triggered by an invalid jwt token
            if (error instanceof HttpErrorResponse && error.status === 401) {
                return refreshTokenMethod(newReq, next);
            }

            //Catch "403 Forbidden" responses: these are triggered when a user does not have the required authorization claim or permission for that resource.
            //Catch "404 Not found" responses: these are triggered when a resource is not found
            if (
                error instanceof HttpErrorResponse &&
                (error.status === 403 || error.status === 404)
            ) {
                router.navigate(['not-found']);
            }

            return throwError(error);
        })
    );
};
